import { render, staticRenderFns } from "./Bottombar.vue?vue&type=template&id=cefc8cbc&lang=html&"
import script from "./Bottombar.vue?vue&type=script&lang=js&"
export * from "./Bottombar.vue?vue&type=script&lang=js&"
import style0 from "./Bottombar.vue?vue&type=style&index=0&id=cefc8cbc&prod&lang=stylus&module=attr&"




function injectStyles (context) {
  
  this["attr"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BottombarCta: require('/var/www/malayan/dev/web/malayan-web/components/global/BottombarCta.vue').default,BackToTop: require('/var/www/malayan/dev/web/malayan-web/components/global/BackToTop.vue').default})
