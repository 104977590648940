import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _24128f8f = () => interopDefault(import('../pages/about-us/index.vue' /* webpackChunkName: "pages/about-us/index" */))
const _cc6b4b3c = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _47a9685c = () => interopDefault(import('../pages/contact-us/index.vue' /* webpackChunkName: "pages/contact-us/index" */))
const _d2e4e6e4 = () => interopDefault(import('../pages/home-insurance-philippines.vue' /* webpackChunkName: "pages/home-insurance-philippines" */))
const _1e34801e = () => interopDefault(import('../pages/index.php.vue' /* webpackChunkName: "pages/index.php" */))
const _cbb9dee8 = () => interopDefault(import('../pages/legal-notices/index.vue' /* webpackChunkName: "pages/legal-notices/index" */))
const _366c1a44 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _7c163a92 = () => interopDefault(import('../pages/pdf/index.vue' /* webpackChunkName: "pages/pdf/index" */))
const _f2ec085c = () => interopDefault(import('../pages/PDFs/index.vue' /* webpackChunkName: "pages/PDFs/index" */))
const _52006bf0 = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _350bb6d6 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _edd31eca = () => interopDefault(import('../pages/quick-links.vue' /* webpackChunkName: "pages/quick-links" */))
const _014e8159 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _06e4730f = () => interopDefault(import('../pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _9aad80b6 = () => interopDefault(import('../pages/terms-and-conditions/index.vue' /* webpackChunkName: "pages/terms-and-conditions/index" */))
const _57bed6e3 = () => interopDefault(import('../pages/testmode.vue' /* webpackChunkName: "pages/testmode" */))
const _495fc589 = () => interopDefault(import('../pages/travel-insurance-philippines.vue' /* webpackChunkName: "pages/travel-insurance-philippines" */))
const _081a3ec0 = () => interopDefault(import('../pages/about/branch-location.vue' /* webpackChunkName: "pages/about/branch-location" */))
const _1caf6dd4 = () => interopDefault(import('../pages/about/history.vue' /* webpackChunkName: "pages/about/history" */))
const _2ea27056 = () => interopDefault(import('../pages/about/ygc.vue' /* webpackChunkName: "pages/about/ygc" */))
const _563dc7ff = () => interopDefault(import('../pages/claims-and-support/claims-form/index.vue' /* webpackChunkName: "pages/claims-and-support/claims-form/index" */))
const _21bedec4 = () => interopDefault(import('../pages/digital-partners/code.vue' /* webpackChunkName: "pages/digital-partners/code" */))
const _45d236c2 = () => interopDefault(import('../pages/search/news/index.vue' /* webpackChunkName: "pages/search/news/index" */))
const _70472848 = () => interopDefault(import('../pages/uploads/annual-report/pdf/_slug/index.vue' /* webpackChunkName: "pages/uploads/annual-report/pdf/_slug/index" */))
const _60ca6cec = () => interopDefault(import('../pages/about/company-background/_slug/index.vue' /* webpackChunkName: "pages/about/company-background/_slug/index" */))
const _1da0fbf3 = () => interopDefault(import('../pages/news/category/_category_slug/index.vue' /* webpackChunkName: "pages/news/category/_category_slug/index" */))
const _10d910a3 = () => interopDefault(import('../pages/news/details/_redirect/index.vue' /* webpackChunkName: "pages/news/details/_redirect/index" */))
const _11c9d05a = () => interopDefault(import('../pages/products/general/_redirect/index.vue' /* webpackChunkName: "pages/products/general/_redirect/index" */))
const _2a7a57da = () => interopDefault(import('../pages/products/packaged/_redirect/index.vue' /* webpackChunkName: "pages/products/packaged/_redirect/index" */))
const _532f0dad = () => interopDefault(import('../pages/uploads/brochure/_slug/index.vue' /* webpackChunkName: "pages/uploads/brochure/_slug/index" */))
const _5ca82866 = () => interopDefault(import('../pages/uploads/form/_slug/index.vue' /* webpackChunkName: "pages/uploads/form/_slug/index" */))
const _7e312848 = () => interopDefault(import('../pages/uploads/news/_slug/index.vue' /* webpackChunkName: "pages/uploads/news/_slug/index" */))
const _531ff50a = () => interopDefault(import('../pages/about-us/_redirect/index.vue' /* webpackChunkName: "pages/about-us/_redirect/index" */))
const _4178d422 = () => interopDefault(import('../pages/claims-and-support/_slug/index.vue' /* webpackChunkName: "pages/claims-and-support/_slug/index" */))
const _44700f9e = () => interopDefault(import('../pages/news/_news_article/index.vue' /* webpackChunkName: "pages/news/_news_article/index" */))
const _811e64ba = () => interopDefault(import('../pages/products/_category/index.vue' /* webpackChunkName: "pages/products/_category/index" */))
const _675463b2 = () => interopDefault(import('../pages/products/_category/_product/index.vue' /* webpackChunkName: "pages/products/_category/_product/index" */))
const _666a1db3 = () => interopDefault(import('../pages/products/_category/_product/application/index.vue' /* webpackChunkName: "pages/products/_category/_product/application/index" */))
const _0934f55a = () => interopDefault(import('../pages/products/_category/_product/Card-type/index.vue' /* webpackChunkName: "pages/products/_category/_product/Card-type/index" */))
const _c6ec316a = () => interopDefault(import('../pages/products/_category/_product/application/canceled/index.vue' /* webpackChunkName: "pages/products/_category/_product/application/canceled/index" */))
const _661b1a3b = () => interopDefault(import('../pages/products/_category/_product/application/card-type/index.vue' /* webpackChunkName: "pages/products/_category/_product/application/card-type/index" */))
const _bc5a4f32 = () => interopDefault(import('../pages/products/_category/_product/application/confirmation/index.vue' /* webpackChunkName: "pages/products/_category/_product/application/confirmation/index" */))
const _f0939518 = () => interopDefault(import('../pages/products/_category/_product/application/manual-quotation.vue' /* webpackChunkName: "pages/products/_category/_product/application/manual-quotation" */))
const _dd6a0d2c = () => interopDefault(import('../pages/products/_category/_product/application/physical-card/index.vue' /* webpackChunkName: "pages/products/_category/_product/application/physical-card/index" */))
const _887d2ef8 = () => interopDefault(import('../pages/products/_category/_product/application/virtual-card/index.vue' /* webpackChunkName: "pages/products/_category/_product/application/virtual-card/index" */))
const _117d9894 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _24128f8f,
    name: "about-us"
  }, {
    path: "/contact",
    component: _cc6b4b3c,
    name: "contact"
  }, {
    path: "/contact-us",
    component: _47a9685c,
    name: "contact-us"
  }, {
    path: "/home-insurance-philippines",
    component: _d2e4e6e4,
    name: "home-insurance-philippines"
  }, {
    path: "/index.php",
    component: _1e34801e,
    name: "index.php"
  }, {
    path: "/legal-notices",
    component: _cbb9dee8,
    name: "legal-notices"
  }, {
    path: "/news",
    component: _366c1a44,
    name: "news"
  }, {
    path: "/pdf",
    component: _7c163a92,
    name: "pdf"
  }, {
    path: "/PDFs",
    component: _f2ec085c,
    name: "PDFs"
  }, {
    path: "/privacy-policy",
    component: _52006bf0,
    name: "privacy-policy"
  }, {
    path: "/products",
    component: _350bb6d6,
    name: "products"
  }, {
    path: "/quick-links",
    component: _edd31eca,
    name: "quick-links"
  }, {
    path: "/search",
    component: _014e8159,
    name: "search"
  }, {
    path: "/services",
    component: _06e4730f,
    name: "services"
  }, {
    path: "/terms-and-conditions",
    component: _9aad80b6,
    name: "terms-and-conditions"
  }, {
    path: "/testmode",
    component: _57bed6e3,
    name: "testmode"
  }, {
    path: "/travel-insurance-philippines",
    component: _495fc589,
    name: "travel-insurance-philippines"
  }, {
    path: "/about/branch-location",
    component: _081a3ec0,
    name: "about-branch-location"
  }, {
    path: "/about/history",
    component: _1caf6dd4,
    name: "about-history"
  }, {
    path: "/about/ygc",
    component: _2ea27056,
    name: "about-ygc"
  }, {
    path: "/claims-and-support/claims-form",
    component: _563dc7ff,
    name: "claims-and-support-claims-form"
  }, {
    path: "/digital-partners/code",
    component: _21bedec4,
    name: "digital-partners-code"
  }, {
    path: "/search/news",
    component: _45d236c2,
    name: "search-news"
  }, {
    path: "/uploads/annual-report/pdf/:slug",
    component: _70472848,
    name: "uploads-annual-report-pdf-slug"
  }, {
    path: "/about/company-background/:slug",
    component: _60ca6cec,
    name: "about-company-background-slug"
  }, {
    path: "/news/category/:category_slug",
    component: _1da0fbf3,
    name: "news-category-category_slug"
  }, {
    path: "/news/details/:redirect",
    component: _10d910a3,
    name: "news-details-redirect"
  }, {
    path: "/products/general/:redirect",
    component: _11c9d05a,
    name: "products-general-redirect"
  }, {
    path: "/products/packaged/:redirect",
    component: _2a7a57da,
    name: "products-packaged-redirect"
  }, {
    path: "/uploads/brochure/:slug",
    component: _532f0dad,
    name: "uploads-brochure-slug"
  }, {
    path: "/uploads/form/:slug",
    component: _5ca82866,
    name: "uploads-form-slug"
  }, {
    path: "/uploads/news/:slug",
    component: _7e312848,
    name: "uploads-news-slug"
  }, {
    path: "/about-us/:redirect",
    component: _531ff50a,
    name: "about-us-redirect"
  }, {
    path: "/claims-and-support/:slug",
    component: _4178d422,
    name: "claims-and-support-slug"
  }, {
    path: "/news/:news_article",
    component: _44700f9e,
    name: "news-news_article"
  }, {
    path: "/products/:category",
    component: _811e64ba,
    name: "products-category"
  }, {
    path: "/products/:category/:product",
    component: _675463b2,
    name: "products-category-product"
  }, {
    path: "/products/:category/:product/application",
    component: _666a1db3,
    name: "products-category-product-application"
  }, {
    path: "/products/:category/:product/Card-type",
    component: _0934f55a,
    name: "products-category-product-Card-type"
  }, {
    path: "/products/:category/:product/application/canceled",
    component: _c6ec316a,
    name: "products-category-product-application-canceled"
  }, {
    path: "/products/:category/:product/application/card-type",
    component: _661b1a3b,
    name: "products-category-product-application-card-type"
  }, {
    path: "/products/:category/:product/application/confirmation",
    component: _bc5a4f32,
    name: "products-category-product-application-confirmation"
  }, {
    path: "/products/:category/:product/application/manual-quotation",
    component: _f0939518,
    name: "products-category-product-application-manual-quotation"
  }, {
    path: "/products/:category/:product/application/physical-card",
    component: _dd6a0d2c,
    name: "products-category-product-application-physical-card"
  }, {
    path: "/products/:category/:product/application/virtual-card",
    component: _887d2ef8,
    name: "products-category-product-application-virtual-card"
  }, {
    path: "/",
    component: _117d9894,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
